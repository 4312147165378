import React from "react";
import {
  Container,
  Grid,
  Segment,
  Button,
  Icon,
  List,
} from "semantic-ui-react";

import LayoutInner from "../components/layout/layout-inner";
import SEO from "../components/seo/seo";
import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";
import {
  INFO_EMAIL,
  SCHOOLS_PHONE,
  SCHOOLS_PHONE_WITH_COUNTRY_CODE,
  SUPPORT_OPEN_CLOSE_TIMES,
  SUPPORT_PHONE,
  SUPPORT_PHONE_WITH_COUNTRY_CODE,
  WHATSAPP_NUMBER
} from "../constants";

import style from "./contact.module.css";

const Page = () => (
  <LayoutInner>
    <SEO
        title="Contact Us"
        keywords={[`rocksteady`]}
        description="The Rocksteady customer service team are available Monday-Thursday 8am-8pm, Friday 8am-6pm. Contact us via telephone, email, WhatsApp or web chat."
    />

    <Container>
      <SecondaryNavBar
        product="generic"
        title="Contact"
        titleActive={true}
        titlePath="/contact/"
        links={[]}
        // cta="FAQs"
        // ctaLink="https://support.rocksteadymusicschool.com/hc/en-gb"
      />
      <div className={style.contactPageWrapper}>
        <Grid padded stackable>
          <Grid.Row columns={2}>
            <Grid.Column className={style.blueColumn}>
              <Segment basic>
                <h2>NEW CUSTOMERS</h2>
                <p>
                  Lessons are booked on a first come first served basis. The
                  quickest and easiest way to sign up is online. Follow the link
                  below to secure your place now.
                </p>
                <Button
                  primary
                  href="https://booking.rocksteadymusicschool.com/"
                  compact
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign up
                </Button>
              </Segment>
            </Grid.Column>
            <Grid.Column className={style.greyColumn}>
              <Segment basic>
                <h2>EXISTING CUSTOMERS</h2>

                <p>If you want to:</p>
                <ul>
                  <li>Update your details</li>
                  <li>
                    Access and change lesson, timetable and instruments
                    information and enrol an additional child
                  </li>
                  <li>Find information about your Band Leader</li>
                  <li>Get answers to common questions</li>
                  <li>
                    Use Rocksteady at Home, our Distance Learning Programme, if
                    you’re an active subscriber
                  </li>
                  <li>And more...</li>
                </ul>

                <Button
                  primary
                  href="https://backstage.rocksteadymusicschool.com/sign-in"
                  compact
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="user outline" />
                  Login to Backstage
                </Button>
              </Segment>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column textAlign="center">
              <h4 className={style.ifYouWouldLike}>
                If you would like to contact us via email or telephone we would
                love to hear from you...
              </h4>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2}>
            <Grid.Column className={style.blueColumn}>
              <Segment basic>
                <h2>CALL</h2>
                <p>
                  General Enquiries
                  <br />
                  <span className={style.featuredNumber}>
                    <a href={`tel:${SUPPORT_PHONE_WITH_COUNTRY_CODE}`}>
                      {SUPPORT_PHONE}
                    </a>
                  </span>
                </p>
                <p>
                  Schools
                  <br />
                  <a href={`tel:${SCHOOLS_PHONE_WITH_COUNTRY_CODE}`}>
                    {SCHOOLS_PHONE}
                  </a>
                </p>
              </Segment>
            </Grid.Column>
            <Grid.Column className={style.greyColumn}>
              <Segment basic>
                <h2>MESSAGE</h2>
                <p>
                  General Enquiries
                  <br />
                  <a href="https://support.rocksteadymusicschool.com" target="_blank" rel="noopener noreferrer">
                    Submit an enquiry form
                  </a>
                  <br />
                  <a href={`mailto:${INFO_EMAIL}`}>
                    {INFO_EMAIL}
                  </a>
                </p>
                <iframe
                  width="250"
                  height="50"
                  style={{ border: 0 }}
                  title="WhatsApp Widget"
                  src={`https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=green&size=standard&radius=4px&label=Message us on WhatsApp&number=${WHATSAPP_NUMBER}`}>
                </iframe>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment basic>
                <h2>ADDRESS</h2>
                <p>
                  <strong>ROCKSTEADY MUSIC SCHOOL</strong>
                  <br />
                  Bohunt Manor
                  <br />
                  Portsmouth Road
                  <br />
                  Liphook
                  <br />
                  Hampshire
                  <br />
                  GU30 7DL
                  <br />
                  United Kingdom
                </p>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment basic>
                <h2>OFFICE HOURS</h2>
                <List compact>
                  {Object.entries(SUPPORT_OPEN_CLOSE_TIMES).map(([day, [openAt, closeAt]]) => (
                    <List.Item>
                      <div className={style.openHoursRow}>
                        <div className={style.day}>{`${day}:`}</div>
                        <div className={style.hours}>{`${openAt} - ${closeAt}`}</div>
                      </div>
                    </List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Container>
  </LayoutInner>
);

export default Page;
